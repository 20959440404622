<template>
  <div class="home">
    <!-- <div class="container">
      <div><button v-on:click="onAboutBtnClicked">跳转到About页面</button></div>
      <div style="height:10px"></div>
      <div><button v-on:click="onHttpBtnCLicked">HTTP请求</button></div>
    </div> -->

    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad">
      <van-cell v-for="item in list" :key="item" :title="item" />
    </van-list>

  </div>
</template> 

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import axios from 'axios'

export default {
  name: 'Home',
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
    };
  },
  methods: {
    onLoad() {
      // 异步更新数据
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 2000);
    },
    onAboutBtnClicked: function() {
      this.$router.push('/about')
    },
    onHttpBtnCLicked: function() {
      axios.get('https://api.coindesk.com/v1/bpi/currentprice.json')
        .then(res => {
          console.log(res.data)
        })
        .catch(res => {
          console.log(res.data)
        })
    }
  }
}
</script>

<style scoped>

</style>